<template>
  <div class="content">
    <h1>
      <b-breadcrumb>
        <b-breadcrumb-item
          v-if="isLogged()"
          :to="`/candles?filters=${filters}`"
        >
          Candles
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          <span v-if="candle">
            {{ candle.candle_reference }}
          </span>
          <span v-else-if="loadingCount > 0">
            Loading...
          </span>
          <span v-else-if="!candle">
            No candle found
          </span>
        </b-breadcrumb-item>
      </b-breadcrumb>
    </h1>
    <div v-if="candle" class="flex flex-col gap-4">
      <div v-if="isLogged()" class="flex gap-2 justify-end">
        <b-btn
          variant="white"
          size="sm"
          @click="openLabels"
        >
          Print label
        </b-btn>

        <b-btn
          variant="white"
          size="sm"
          @click="openRecipe"
        >
          Print Recipe
        </b-btn>

        <b-btn
          variant="white"
          size="sm"
          @click="openSds"
        >
          SDS
        </b-btn>

        <b-btn
          variant="white"
          size="sm"
          @click="toggleFavorite"
        >
          <div class="flex items-center gap-2">
            <icon-star :checked="candle.is_favorite" />
          </div>
        </b-btn>

        <b-dropdown
          text="Action"
          size="sm"
          variant="primary"
          right
        >
          <b-dropdown-item @click="duplicateCandle" class="text-right">
            Duplicate
          </b-dropdown-item>
          <b-dropdown-item @click="editCandle" class="text-right">
            Edit
          </b-dropdown-item>
          <b-dropdown-item @click="deleteCandle" class="text-right">
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="grid md:grid-cols-2 gap-2">
        <div class="flex flex-col gap-2">
          <h2 class="text-lg font-bold">Details</h2>
          <candle-info :candle="candle" />

          <h2 class="text-lg font-bold">Note</h2>
          <candle-note :id="candle.id" />
        </div>
        <candle-samples v-if="isLogged()" :id="candle.id" />
        <div v-else>
          <router-link
            to="/signin"
            @click="onNavigationClick()"
            class="btn btn-primary btn-sm"
          >
            Sign in to see tests
          </router-link>
        </div>

      </div>
      <div class="flex flex-col gap-2">
        <h2 class="text-lg font-bold">
          Files
        </h2>
        <candle-files :id="candle.id" />
      </div>
    </div>

    <div v-if="candle === null && loadingCount === 0">
      This candle is no longer available.
    </div>

    <label-modal
      v-if="candle"
      :data="labelModal"
      :id="candle.id"
    />

    <create-modal
      :data="createModal"
      @created="onDuplicated"
    />

    <edit-modal
      v-if="candle"
      :data="editModal"
      :id="candle.id"
      @updated="candleUpdated"
    />

    <recipe-modal
      v-if="recipeModal.showModal"
      :data="recipeModal"
    />

    <sds-modal
      v-if="candle"
      :data="sdsModal"
      :id="candle.id"
    />
  </div>
</template>

<script>
import moment from 'moment';

const IconStar = () => import('@/components/global/IconStar.vue');
const CandleFiles = () => import('@/components/candle/CandleFiles.vue');
const CandleInfo = () => import('@/components/candle/CandleInfo.vue');
const CandleNote = () => import('@/components/candle/CandleNote.vue');
const CandleSamples = () => import('@/components/candle/CandleSamples.vue');
const EditModal = () => import('@/components/candle/EditModal.vue');
const CreateModal = () => import('@/components/candle/CreateModal.vue');
const LabelModal = () => import('@/components/candle/LabelModal.vue');
const SdsModal = () => import('@/components/candle/SdsModal.vue');
const RecipeModal = () => import('@/components/candles/RecipeModal.vue');

export default {
  name: 'Candle',
  components: {
    IconStar,
    CandleFiles,
    CandleInfo,
    CandleNote,
    CandleSamples,
    EditModal,
    CreateModal,
    LabelModal,
    SdsModal,
    RecipeModal,
  },
  computed: {
    candleId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: '',
      candle: null,
      editModal: {
        showModal: false,
      },
      createModal: {
        showModal: false,
        source: null,
      },
      labelModal: {
        showModal: false,
      },
      recipeModal: {
        showModal: false,
        candleIds: [],
        candleCount: 1,
      },
      sdsModal: {
        showModal: false,
      },
    };
  },
  methods: {
    consumeFilters() {
      this.filters = this.$route.query.filters ?? '';
      if (this.filters.length > 0) {
        const query = { ...this.$route.query };
        delete query.filters;
        this.$router.replace({ query });
      }
    },
    fetchCandle() {
      this.loadingCount++;
      this.$http
        .get(`/candle/${this.candleId}`)
        .then((res) => {
          this.candle = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch candle: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    toggleFavorite() {
      const changes = {
        is_favorite: !this.candle.is_favorite,
      };
      this.loadingCount++;
      this.$http
        .put(`/candle/${this.candleId}`)
        .send({ changes })
        .then(() => {
          this.candle.is_favorite = !this.candle.is_favorite;
        })
        .catch((err) => {
          this.$toast.error(`Failed to toggle favorite: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onDuplicated(candle) {
      this.$router.push({
        name: 'Candle',
        params: { id: candle.id },
        query: {
          filters: this.filters,
        },
      }, () => {
        this.candle = candle;
      });
    },
    duplicateCandle() {
      const source = JSON.parse(JSON.stringify(this.candle));
      source.id = null;
      source.candle_date = moment().format('YYYY-MM-DD');
      source.candle_reference = '';
      source.candle_note = '';
      source.candle_status = 'testing';
      source.is_favorite = false;
      source.fragrance.forEach((fragrance) => {
        fragrance.id = null;
      });
      source.dye.forEach((dye) => {
        dye.id = null;
      });
      source.additive.forEach((additive) => {
        additive.id = null;
      });
      this.createModal.source = source;
      this.createModal.showModal = true;
    },
    editCandle() {
      this.editModal.showModal = true;
    },
    candleUpdated(candle) {
      this.candle = {
        ...candle,
      };
    },
    deleteCandle() {
      if (!confirm('Do you really wish to delete this candle?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/candle/${this.candleId}`)
        .then(() => {
          this.$router.push({
            name: 'Candles',
            query: {
              filters: this.filters,
            },
          }, () => {
            this.fetchCandle();
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete candle: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    openRecipe() {
      this.recipeModal.showModal = true;
      this.recipeModal.candleIds = [this.candle.id];
    },
    openSds() {
      this.sdsModal.showModal = true;
    },
    openLabels() {
      this.labelModal.showModal = true;
    },
  },
  created() {
    this.consumeFilters();
    this.fetchCandle();
  },
};
</script>

<style lang="scss" scoped>
</style>
